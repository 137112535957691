.divider {
  height: 1px;
  margin: 0;
  padding: 20px 0;
  position: relative;
  width: 100%;
}

.divider::after {
  background-color: var(--color-gray-silver-light);
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

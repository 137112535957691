.container {
  margin: 72px 0 0;
}

.container,
.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.form {
  width: 50%;
}

.iconContainer svg {
  fill: var(--color-gray-silver);
  height: 100px;
  margin: 0 0 40px;
  width: 100px;
}

.textContainer {
  margin: 0 0 20px;
}

.inputContainer {
  margin: 0 0 20px;
  width: 100%;
}

.error {
  color: var(--color-red);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 10px 0 0;
}

.selectContainer {
  position: relative;

  & svg {
    fill: var(--color-gray-text-light);
    height: 10px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    width: 10px;
  }
}

.select {
  appearance: none;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-silver);
  color: var(--color-gray-text);
  display: block;
  font-family: inherit;
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  padding: 20px;
  width: 100%;
}

.headingContainer {
  margin-bottom: 2em;
}

.templates {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-gap: 24px;

  @media (--viewport-s) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-m) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.card {
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-silver);
  cursor: pointer;
  display: block;
  margin: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  transition: background-color 200ms ease-out;
  width: 100%;

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver);
  }

  & svg {
    display: block;
    fill: var(--color-gray-text);
    height: 80%;
    left: 0;
    opacity: 0.02;
    position: absolute;
    top: 0;
    transform: translate(-20%, -10%);
    width: 80%;
  }
}

.inner {
  bottom: 0;
  color: var(--color-gray-text);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  left: 0;
  line-height: var(--line-height-l);
  padding: 24px;
  position: absolute;
  text-align: left;
}

.title {
  display: block;
  margin: 0 0 12px;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-xs);
}

.group {
  margin: 0 0 48px;
}

.colorRow {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin: 0 0 24px;
  }

  & > *:not(:last-child) {
    margin: 0 8px 0 0;
  }
}

.colorPreview {
  border-radius: var(--border-radius);
  height: 60px;
  width: 20px;
}

.deleteButton {
  cursor: pointer;
  height: 60px;
  margin: 0;
  opacity: 0.2;
  padding: 0;
  position: relative;
  transition: opacity 200ms ease-out;
  width: 60px;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.deleteButtonInner {
  align-items: center;
  display: flex;
  justify-content: center;

  & svg {
    fill: var(--color-gray-text);
    height: 16px;
    width: 16px;
  }
}

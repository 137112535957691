.container {
  height: 16px;
  position: relative;
  width: 16px;
}

.container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

.tooltip {
  background-color: var(--color-editor-shade);
  border-radius: var(--border-radius);
  color: var(--color-white);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  left: 50%;
  line-height: var(--line-height-s);
  opacity: 0;
  padding: 4px 8px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate(-50%, -140%);
  transition: opacity 200ms ease-out;
  visibility: hidden;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid var(--color-editor-shade);
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 90%);
  }
}

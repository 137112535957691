.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > *:first-child {
    margin-bottom: 24px;
  }

  @media (--viewport-l) {
    flex-direction: row;

    & > *:first-child {
      margin-bottom: 0;
    }

    & > * {
      width: calc(50% - 20px);
    }
  }
}

.block {
  background-color: var(--color-gray-silver-background);
  border-left: solid 2px var(--color-green);
  border-radius: var(--border-radius);
  padding: 24px 48px;
  position: relative;
}

.blockDont {
  border-left: solid 2px var(--color-red);
}

.blockIcon {
  align-items: center;
  background-color: var(--color-green);
  border-radius: 50%;
  border: solid 1px var(--color-green);
  display: flex;
  height: 24px;
  justify-content: center;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 24px;

  & svg {
    display: blcok;
    fill: var(--color-white);
    height: 12px;
    width: 12px;
  }
}

.blockIconDont {
  background-color: var(--color-red);
  border: solid 1px var(--color-red);
}

.container {
  background-color: var(--color-gray-silver-background);
  border-bottom: solid 2px var(--color-gray-silver);
  left: 0;
  padding: 12px 0;
  position: fixed;
  top: 0;
  transform-origin: 50% 0;
  transform: translateY(-100%);
  transition: transform 200ms ease-out;
  width: 100%;
  z-index: var(--z-index-editBar);
}

.inner {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.group {
  align-items: center;
  color: var(--color-gray-silver-dark);
  display: flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-s);

  & strong {
    color: var(--color-gray-text);
    font-weight: var(--font-weight-semibold);
  }

  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.container {
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchInput {
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-silver);
  color: var(--color-gray-text);
  font-size: var(--font-size-s);
  line-height: var(--line-height-xs);
  padding: 10px;
}

.grid {
  align-items: flex-start;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  height: 580px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.grid.isFull {
  height: 80vh;

  @media (--viewport-m) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (--viewport-l) {
    grid-template-columns: repeat(8, 1fr);
  }
}

.mediaPreview {
  background-color: transparent;
  border: 0;
  display: block;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;

  &:hover .mediaButtonsContainer,
  &:focus .mediaButtonsContainer {
    display: flex;
  }
}

.mediaPreviewImageContainer {
  background-color: var(--color-gray-silver-background);
  display: block;
  padding-top: 100%;
  position: relative;
  width: 100%;

  & svg {
    width: 25%;
    height: 25%;
    fill: var(--color-gray-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.mediaPreviewLabel {
  display: block;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin-top: 10px;
  word-break: break-word;
}

.mediaButtonsContainer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  display: none;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.mediaButton {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-silver);
  cursor: pointer;
  height: 30px;
  position: relative;
  transition: background-color 200ms ease-out;
  width: 30px;

  &:hover,
  &:focus {
    background-color: var(--color-gray-silver);
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.mediaButtonInner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  & svg {
    fill: var(--color-gray-text);
    height: 16px;
    width: 16px;
  }
}

.uploadButtonInput {
  display: none;
}

.highlight {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-semibold);
  height: 100%;
  justify-content: center;
  left: 0;
  line-height: var(--line-height-s);
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
}

.progress {
  background-color: var(--color-editor-blue);
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  transition: width 200ms ease-out;
  width: 0%;
}

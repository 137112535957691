.group {
  margin: 0 0 20px;
}

.error {
  color: var(--color-red);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0 0 10px;
}

.withEditButton {
  position: relative;
}

.editButtonContainer {
  bottom: 5%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
}

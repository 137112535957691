.container {
  padding: 32px 0;

  @media (--viewport-m) {
    padding: 48px 0;
  }
}

.cardsContainer {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;

  @media (--viewport-m) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cardsColumn {
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}

.card {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  color: var(--color-gray-text);
  display: block;
  overflow: hidden;
  text-decoration: none;
  transform: translateY(0);
  transition: transform 200ms ease;
  will-change: transform;

  &:hover,
  &:focus {
    transform: translateY(-8px);

    & .title {
      text-decoration: underline;
    }
  }
}

.content {
  padding: 16px;

  @media (--viewport-m) {
    padding: 24px 24px 16px;
  }
}

.title {
  color: var(--color-gray-text);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-s);
  margin: 0 0 4px;

  @media (--viewport-m) {
    font-size: var(--font-size-l);
  }
}

.description {
  font-size: var(--font-size-s);
  line-height: var(--line-height-l);
  margin: 0;

  @media (--viewport-m) {
    font-size: var(--font-size-m);
  }
}

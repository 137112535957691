.group {
  margin: 0 0 2em;
}

.imageInfo {
  align-items: center;
  color: var(--color-gray-silver-dark);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  margin: 8px 0 0;

  & svg {
    fill: var(--color-gray-silver-dark);
    height: 16px;
    margin: 0 4px 0 0;
    width: 16px;
  }
}

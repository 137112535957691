.itemContainer:not(:first-child) {
  margin: 24px 0;

  @media (--viewport-m) {
    margin: 48px 0;
  }
}

.itemContainer :global(.toolbar) {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.itemContainer:hover .itemContainerBorder,
.itemContainer:hover .itemContainerTools,
.itemContainer:hover :global(.toolbar) {
  opacity: 1;
}

.itemContainerBorder {
  background-color: var(--color-editor-shade);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(-4px);
  transition: opacity 200ms ease-out;
  width: 4px;
  z-index: 10;
}

.itemContainerTools {
  opacity: 0;
  position: sticky;
  top: 80px;
  transition: opacity 200ms ease-out;
  z-index: 10;
}

.contentItemTools {
  height: 100%;
  left: 0;
  padding: 0 16px;
  position: absolute;
  top: 0;
  transform: translateX(calc(-100% - 16px));
  z-index: 2;
}

.contentItemToolsContainer {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-gray-text);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  position: relative;
}

.contentItemToolsContainerOpen {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.contentItemToolsContainer:not(:last-child) {
  margin-bottom: 12px;
}

.contentItemToolsContainer:after {
  background-color: transparent;
  content: '';
  height: calc(100% + 14px);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 14px);
  z-index: -1;
}

.contentItemToolsButtonContainer:not(:first-child) {
  border-top: solid 1px var(--color-gray-silver);
  margin-top: 8px;
  padding-top: 8px;
}

.contentItemToolsButton,
.addButton {
  cursor: pointer;
  height: 16px;
  outline: none;
  width: 16px;
}

.contentItemToolsButton svg,
.addButton svg {
  fill: var(--color-gray-text);
  height: 16px;
  transition: fill 200ms ease-out;
  width: 16px;
}

.contentItemToolsButton:hover svg,
.addButton:hover svg {
  fill: var(--color-editor-blue);
}

.contentItemToolsButton.up {
  transform: rotate(-90deg);
}

.contentItemToolsButton.down {
  transform: rotate(90deg);
}

.addButtons {
  align-items: center;
  background-color: var(--color-white);
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border: solid 1px var(--color-gray-text);
  border-left: none;
  display: flex;
  left: 100%;
  padding: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.addButtons > *:not(:last-child) {
  margin-right: 24px;
}

.addButtonDivider {
  background-color: var(--color-gray-silver-dark);
  height: 16px;
  width: 1px;
}

.emptyText {
  color: var(--color-gray-text-light);
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
}

.group {
  margin: 0 0 30px;
}

.row {
  border: solid 1px var(--color-gray-silver);

  &:not(:first-child) {
    border-top: none;
  }
}

.header {
  align-items: center;
  border-bottom: none;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
  line-height: 1;
  padding: 20px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  & svg {
    fill: var(--color-gray-text);
    height: 8px;
    transform: rotate(0deg);
    transition: transform 200ms ease-out;
    width: 8px;
  }
}

.headerOpen svg {
  transform: rotate(90deg);
}

.content {
  padding: 0 20px;
  background-color: var(--color-gray-silver-background);
  max-height: 0;
  transition: max-height 200ms ease-out, padding 200ms ease-out;
  overflow: hidden;

  & > *:not(:last-child) {
    margin: 0 0 20px;
  }
}

.contentOpen {
  padding: 20px;
  max-height: 1000px;
}

.imageInfo {
  align-items: center;
  color: var(--color-gray-silver-dark);
  display: flex;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  margin: 8px 0 0;

  & svg {
    fill: var(--color-gray-silver-dark);
    height: 16px;
    margin: 0 4px 0 0;
    width: 16px;
  }
}

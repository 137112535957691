.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;

  @media (--viewport-m) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.colorSquareContainer {
  padding-bottom: 90%;
  position: relative;
}

.colorSquare {
  border-radius: var(--border-radius);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  line-height: var(--line-height-xs);
  padding: 20px;
  position: absolute;
  top: 0;
  width: 100%;

  &.inverted {
    color: var(--color-gray-text);
  }
}

.title {
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  margin-bottom: 20px;
}

.colorValueContainer {
  align-items: center;
  display: flex;
  font-size: var(--font-size-s);
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.colorValue {
  text-align: right;
}

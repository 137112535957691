.iframe {
  border: 0;
  height: 100%;
  width: 100%;
}

.background {
  background-color: var(--color-gray-silver-background);
}

.container {
  background-color: var(--color-white);
  border: solid 1px var(--color-gray-silver-light);
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 10px 100px 10px 10px;
  position: relative;
}

.children {
  overflow: hidden;
}

.dragButton {
  align-items: center;
  background-color: var(--color-white);
  border-left: solid 1px var(--color-gray-silver-light);
  color: var(--color-gray-silver);
  cursor: ew-resize;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}

.dragIcon {
  fill: currentColor;
  height: 20px;
  width: 20px;
}

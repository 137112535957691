.toolbarWrapper {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 100px;
  z-index: 20;
}

.toolbar {
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  border: solid 1px var(--color-editor-shade);
  display: inline-flex;
  padding: 16px 0;
}

.toolbarGroup {
  align-items: center;
  display: flex;
  padding: 0 16px;

  &:not(:last-child) {
    border-right: solid 1px var(--color-gray-silver);
  }
}

.toolbarButton {
  background-color: transparent;
  border-bottom: solid 2px transparent;
  cursor: pointer;
  display: block;
  height: 32px;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  transition: opacity 200ms ease-out;
  width: 32px;

  & svg {
    fill: var(--color-gray-text);
    height: 16px;
    transition: fill 200ms ease-out;
    width: 16px;
  }

  &:hover svg,
  &:focus svg {
    fill: var(--color-editor-blue);
  }
}

.toolbarButtonInner {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.isActive svg {
  fill: var(--color-editor-blue);
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.1;

  &:hover,
  &:focus {
    opacity: 0.1;
  }
}

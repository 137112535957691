.colorContainer {
  position: relative;
}

.colorPickerContainer {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 10;
}

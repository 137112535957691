.group {
  margin: 0 0 2em;
}

.imagesGroup {
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;

  & > * {
    width: calc(50% - 20px);
  }
}

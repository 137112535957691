.editButtonContainer {
  bottom: 32px;
  display: none;
  position: fixed;
  right: 32px;
  z-index: var(--z-index-editBar);

  @media (--viewport-m) {
    display: block;
  }
}

.container {
  position: relative;

  &.isEditing {
    border: 2px dashed var(--color-gray-silver);
    min-height: 80px;

    @media (--viewport-l) {
      min-height: 140px;
    }
  }
}

.editButtonContainer {
  display: flex;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
}

.imageContainer {
  height: 40vh;
  overflow: hidden;
  position: relative;
  z-index: 1;

  & img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.group {
  align-items: flex-end;
  display: flex;

  &:not(:last-child) {
    margin: 0 0 2em;
  }

  & > *:not(:last-child) {
    margin-right: 20px;
  }
}

.inputContainer {
  flex-grow: 1;
}

.children {
  cursor: zoom-in;
}

.lightBox {
  cursor: zoom-out;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-overlay);
}

.background {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.content {
  height: 100%;
  left: 50%;
  max-height: 90vh;
  max-width: 90vw;
  position: absolute;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  & > * {
    height: 100%;
    left: 0;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.image {
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  width: 100%;
}

.autoWidth {
  height: auto;
  width: auto;
}

.figure {
  display: block;
  margin: 0;
}

.caption {
  color: var(--color-gray-text-light);
  font-family: inherit;
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 6px 0 0;
  padding: 0;

  @media (--viewport-m) {
    margin: 12px 0 0;
  }
}

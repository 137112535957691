.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin: 0 0 16px;
  }

  @media (--viewport-m) {
    flex-direction: row;

    & > *:not(:last-child) {
      margin: 0 16px 0 0;
    }
  }
}
